.filterButton {
  margin-top: 5px;
  margin-right: 10px;
  background: none;
  border: 0;
}

.switchContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  gap: 1em;
  color: gray;
  margin-left: 0.5em;

  p {
    margin-top: 0.5em;
    text-align: center;
  }
}

.ModalContainerBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 3px;

  h3 {
    text-align: center;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
  }

  .versionFormContainer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    gap: 20px;
  }

  .resetButton {
    margin-top: 20px;
    border-color: rgb(6, 27, 91);
    color: rgb(6, 27, 91);
  }
  .validationButton {
    margin-top: 20px;
    background-color: rgb(6, 27, 91);
  }

  .dateRangePickerButtonContainer {
    border: 0;
    background: none;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;

    Label {
      text-align: left;
    }
  }
}
