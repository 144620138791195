.applicationContainer {
  height: 100vh;
  display: flex;
  flex-direction: row;

  .add-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    font-size: 2rem;
  }
  &.transformed {
    transform: scale(0);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
}
