.appleApplicationDetailPageContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.appleApplicationDetailContentContainer {
  flex: 1; 
}

.headerDetailContainer {
  p {
    margin: 0;
  }
  display: flex;

  button {
    background-color: white;
    border: 0;
  }

  button:hover {
    cursor: pointer;
  }

  svg {
    margin-top: 0.05em;
    margin-right: 0.3em;
  }

  .appleContainer {
    display: flex;
    color: rgb(48, 48, 48);

    .appleText {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 0.6em;
      margin-right: 1em;
    }
  }

  .nameSeparator {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .appName {
    margin-left: 0.3em;
  }
}
