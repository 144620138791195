.pageLoginContainer {
  background-color: rgb(247, 249, 252);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginFormContainer {
  max-width: 800px;
  margin: auto;
}

.LoginFormTitleContainer {
  font-family: 'AlwynRegular', sans-serif;
  font-weight: lighter;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.90);
  display: flex;
  padding-top: 30px;
  justify-content: center;
  padding-bottom: 50px;
  gap: 20px;

  img {
    height: 80px;
  }
}

.LoginFormBodyContainer {
  background-color: rgb(255, 255, 255);

  //shadow
  -moz-box-shadow: 0px 0px 5px #cecece;
  -webkit-box-shadow: 0px 0px 5px #cecece;
  -o-box-shadow: 0px 0px 5px #cecece;
  box-shadow: 0px 0px 5px #cecece;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.loginForm {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;

  Input {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  Button {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: rgb(6, 27, 91);
  }
}
