.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  h2 {
    margin-bottom: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
  }
  .button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    justify-content: space-between;

    button {
      margin-left: 8px;
    }
  }

  .platform-toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 16px;
  }
}

.addAndroidApp {
  margin-top: 0.5em;
  background-color: rgb(230, 252, 252);
  border-radius: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
}
