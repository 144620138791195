.menuContainer {
  width: 290px;
  background-color: #061b5b;
  padding-top: 8px;
}

.navigationComponent {
  margin-left: 24px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 40px;
  height: 80px;

  img {
    height: 48px;
  }

  p {
    color: white;
    font-size: large;
    font-weight: bold;
    margin: 0 0 0 16px;
  }
}
