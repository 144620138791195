.searchIcon {
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
  span {
    color: #C0CBDE;
  }
  svg {
    color: #C0CBDE;
  }
}

.searchInputContainer {
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  border-bottom: 3px solid rgba(225, 229, 242, 0.78);
  padding-bottom: 2px;

}

.inputSearch {
  border: 0;
  width: 100%;
  height: 100%;
}