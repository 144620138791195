.menuItem {
  span {
    color: #C0CBDE;
  }
  svg {
    color: #C0CBDE;
  }

}

.active {
  span {
    color: white;
  }

  svg {
    color: white;
  }
}