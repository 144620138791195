.appCounter {
  font-size: 15px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.clientTableContainer {
  height: 100vh;
}

.tableBody {
  tr {
    border-bottom-width: 4px;
    border-style: solid;
    border-color: #e1f5fe;
  }
  tr:last-child {
    border-bottom-width: 0;
  }

  .greenLine {
    color: green;
  }
  .orangeLine {
    color: orange;
  }
}

.noResult {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableHead {
  th {
    border-bottom-width: 0;
  }
}

.tableCellReady {
  width: 48px;
  text-align: center;

  div {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgb(214, 233, 195);
    color: darkgreen;
    border-radius: 5px;
  }
}

.tableCellKO {
  width: 48px;
  text-align: center;

  div {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: lightcoral;
    color: darkred;
    border-radius: 5px;
  }
}
.table--header-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: center;

  .update-btn {
    background-color: transparent;
    color: rgb(88, 174, 235);
    border: 2px solid rgb(78, 175, 244);
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px;
    font-weight: bold;

    &:hover {
      background-color: rgba(35, 150, 230, 0.1);
      transition-duration: 0.5s;
    }
  }
}
