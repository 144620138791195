.anomaly-container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

.anomaly-content {
  flex-grow: 1;
  overflow-y: scroll;
}
