.ApplicationDetailContainer {
  margin: 30px;
  display: flex;
  flex-direction: column;

  div {
    margin-bottom: 0.5em;
  }

  section {
    margin-bottom: 1em;

    h6 {
      display: flex;
      align-items: center;
      gap: 0.5em;
      margin-bottom: 0.5em;
    }

    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 5rem;
      margin: 0 35px;

      .MuiTextField-root {
        width: 100%;
      }
    }
  }
}
