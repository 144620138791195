.header {
  height: 60px;
  border-bottom: 3px solid #e1e5f2;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;

  svg {
    margin-left: 30px;
    margin-right: 20px;
  }
}
